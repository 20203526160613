.checkout-form-wrapper {
    .checkout-form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 320px;
        max-width: 720px;

        [data-component-name="modulor-field"],
        [data-component-name="modulor-dropdown"] {
            margin-bottom: 8px;
        }

        div[data-component-name="modulor-error"] {
            position: relative;
            bottom: 10px;
        }

        .pair-row {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 10px;
        }
        &__content {
            .amount-blocks {
              margin: 4px 0 16px;
            }
        }
        &__footer {
            margin: 16px 0 16px;
        }

        &__bottom-icons-box {
            margin-top: 14px;
            display: flex;
            justify-content: center;
            & svg {
                margin: 0 12px 24px;
            }
        }
    }
    [data-component-name="modulor-alert"] > div {
        min-width: 350px;
        
        [data-id="modulor-alert-content"] {
            margin-bottom: 0;
        }
    }
    .cvv-modal-content {
        padding: 30px 0;
        .is-opened {
            [data-component-name="modulor-tooltip"] {
                /* couldn't avoid important due to inline styling. 
                Such correction we needed for the toolbar in a quite narrow alert component */
                left: 14px !important;
                top: -5px !important;
                width: 247px;
            }
        }
    }
}