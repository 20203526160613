.conversion-block {

  &__container {
    margin: 0 0 8px 0;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .info-icon {
      margin-left: 3px;
      cursor: pointer;
      width: 12px !important;
      height: 12px !important;
    }
    [data-component-name="modulor-tooltip"] {
      z-index: -1;
      button {
        max-width: 120px;
        max-height: 32px;
      }
    }

    &.is-opened {
      [data-component-name="modulor-tooltip"] {
        z-index: 10;
        top: -8px !important;
      }
    }
  }
}
