body {
  margin: 0;

  a {
    text-decoration: none;
  }
}

iframe[title='iframe for cashier'] {
  border: 1px solid #e6e4e4;
  box-shadow: 0 0 4px rgb(0 0 0 / 20%);
  flex: 0 0 auto;
  width: 100%;
}

.root {
  display: flex;

  &__menu-open {
    iframe[title='iframe for cashier'] {
      flex: 0 1 auto;
    }
  }
}

.menu {
  &-btn {
    align-items: center;
    background: rgb(14 147 240 / 80%);
    cursor: pointer;
    display: flex;
    height: 20px;
    padding: 6px;
    position: fixed;
    right: 10px;
    top: 10px;
    width: 20px;
    z-index: 1;

    > span,
    > span::before,
    > span::after {
      background-color: #fff;
      display: block;
      height: 20px;
      height: 2px;
      position: absolute;
      width: 20px;
    }

    > span {
      &::before {
        content: '';
        top: -8px;
      }

      &::after {
        content: '';
        top: 8px;
      }
    }
  }

  &-box {
    background-color: #f2f2f2;
    box-shadow: 1px 0 4px rgb(90 90 90 / 40%);
    display: block;
    height: calc(100vh - 80px);
    list-style: none;
    margin: -10px -10px 0 10px;
    padding: 80px 20px 0 0;
    right: -100%;
    visibility: hidden;
    width: 200px;

    &__open {
      flex: 0 0 auto;
      right: 0;
      visibility: visible;
    }
  }

  &-item {
    color: #333;
    cursor: pointer;
    display: block;
    font-size: 20px;
    font-weight: 600;
    margin: 8px 25px;
    padding: 12px 24px;
    text-decoration: none;
    width: 90%;
  }
}

.messages {
  display: flex;
  flex-direction: column;
  height: 200px;
  overflow: auto;
}
