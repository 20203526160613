.status-wrapper {
    display: flex;
    flex-direction: column;
    .status-icon-wrapper {
        margin-bottom: 12px;
    }
    .text-box {
        margin-bottom: 4px;
    }
}
.bottom-button {
    margin: 16px 0 16px;
}