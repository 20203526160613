.deposit-methods {
  &.desktop {
    display: grid;
    grid-gap: 16px;
    &:not(.deposit-methods__disabled) {
      grid-template-columns: 1fr 1fr;
    }

    & > .row {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: 1fr 1fr;
      width: 100%;
    }
  }
  div[data-component-name="modulor-list-view"] {
    border-bottom: none;
  }

  div[class^="modulor_list-cell__icon"] {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__item {
    [data-id="method-logo"] {
      display: flex !important;
      align-items: center;
    }
    [class^="modulor_image__placeholder"],
      svg {
        width: 40px;
        height: auto;
        display: flex !important;
        align-items: center;
      }
    span[data-id="modulor-content-text-title"] {
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
    }
    span[data-id="modulor-content-text-subtitle"] {
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
    }

    &.lost-profit span[data-id="modulor-content-text-subtitle"] {
      color: var(--text-link);
    }

    [data-id="method-icon-disabled"] {
      opacity: 0.5;
    }
  }
}

.method-group-caption {
  display: flex;
  flex: 1 0 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-bottom: 4px;

  &__icon {
    margin-right: 4px;
  }
}
