.info-link-container {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  .info-link {
    display: flex;
    align-items: center;

    --text: var(--text-link);

    &:hover {
      cursor: pointer;
    }
  }
}
