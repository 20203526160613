.cell-wrapper {
    cursor: pointer;

    .has-selected-value {
        padding: 5px 0;
    }
}

.list-wrapper {
    [data-component-name="modulor-list-view"] {
        padding-bottom: 0;
    }
    .expanded-content {
        margin-left: 40px;

        [class^="modulor_list-cell__right"] {
            text-align: right;
        }
    }
    .content-divider {
        margin: 10px 0 10px 55px;
    }
}

.bet-shops-modal {
    [data-testid="modulor-modal-shadow"] {
        display: none;
    }
    [class^="modulor_modal__modal-wrapper"] {
        border: 1px solid var(--divider,var(--divider-main));
    }
}
