.file-upload-container {
  margin-bottom: 16px;

  .file-upload-title {
    margin-bottom: 4px;
  }

  .file-upload-content {
    margin-bottom: 12px;
  }

  .file-uploader-input {
    display: none;
  }
}