.wrapper-button {
  .button-get-code {
    margin-top: 4px;
    min-width: 100px;
    width: fit-content;
    [data-testid="modulor-button-button"] {
      height: 32px;
    }
  }
}


.refresh-code-block {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;

  &_icon {
    margin-right: 5px;
  }
}

.resend-unavailable-text {
  margin-top: 10px;
}