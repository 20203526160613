.cvv-field {
  position: relative;
  [data-id="modulor-password-field-field-icon"] {
    display: none;
  }
  .icon-box {
    position: absolute;
    top: 15px;
    right: 45px;
    width: 25px !important;
    .info-icon {
      svg {
        width: 20px !important;
      }
    }
  }
  .info-tooltip-button {
    margin-top: 10px;
  }
  .tooltip {
    position: absolute;
    left: 32px;
    [data-component-name="modulor-icon"] {
      z-index: 3;
    }
    [data-component-name="modulor-tooltip"] {
      z-index: -1;
    }
    button {
      min-width: 30px;
      max-width: max-content;
      max-height: 32px;
    }
  }
  .is-opened {
    /* tooltip should be on the top layers lvl */
    z-index: 100;
    [data-component-name="modulor-tooltip"] {
      width: 300px;
      /* tooltip should be on the top layers lvl */
      z-index: 100;
      top: -8px !important;
    }
  }
}
