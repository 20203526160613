.tax-info-container {
  margin: 0 0 8px 0;
  line-height: 20px;
  mix-blend-mode: normal;
  display: flex;
  flex-direction: column;
  text-align: left;
  .non-tax-value {
    margin-bottom: 4px;
  }
}
.info-tooltip-button {
  margin-top: 10px;
}
.deductible-amount {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.tax-info-block {
  display: flex;
  flex-direction: column;
  color: #7B756B;
  font-weight: 500;

  .tax-general-block,
  .tax-calculation-block {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tax-block-value {
      margin-left: 5px;
    }
  }
  .info-icon {
    margin-left: 3px;
    cursor: pointer;
    width: 12px !important;
    height: 12px !important;
  }
  [data-component-name="modulor-tooltip"] {
    z-index: -1;
    button {
      max-width: 120px;
      max-height: 32px;
    }
  }

  &.is-opened {
    [data-component-name="modulor-tooltip"] {
      z-index: 10;
      top: -8px !important;
    }
  }
}