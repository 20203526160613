.verification-form {
    margin-top: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    .contact-value {
        margin-bottom: 4px;
    }
    .logical-block {
        margin-bottom: 32px;
        .get-new-code-text {
            margin: 0 5px;
        }
        [data-id="modulor-button-label-text"] {
            display: flex;
            align-items: center;
          }
    }
    .method-kind-info {
        display: block;
        text-align: center;
    }
    .code-fields {
        margin-bottom: 32px;
        .single-digit-field {
            max-width: 48px;
            margin: 0 24px;
        }
    }
    .refresh-code-block {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 56px;
        padding: 0 20px;
        .icon {
            margin-right: 7px;
        }
        margin-bottom: 32px;
    }
    .send-code-button {
        cursor: pointer;
        width: max-content;
        margin-left: auto;
        margin-right: auto;
        height: auto;
    }
    .switch-method-block {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 0;
        .toggler {
            cursor: pointer;
        }
    }
    &__footer {
        z-index: 3;
        width: 100%;
        margin-bottom: 16px;
    }
}
