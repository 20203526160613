.cards-container-wrapper {
    [data-component-name="modulor-list-view"] {
        margin-bottom: 16px;
        padding-bottom: 0;
    }
    .saved-card-label {
        margin: 0 0 6px 0;
    }
    .card-box {
        .action__in-list-cell {
            float: right;
        }
        .radio-btn {
            margin-right: 14px;
        }
    }
}
