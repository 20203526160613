.header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    .header-navigation {
        position: absolute;
        right: 0;
    }
}
.content-page-wrapper {
    [data-component-name="modulor-loader"] {
        display: flex;
        justify-content: center;
        margin-bottom: 50px;
    }
    [data-component-name="modulor-list-cell"] {
        [class^="modulor_list-cell__left"] {
            margin: 10px 0,
        }
    }
}
.bet-shops-modal {
    [data-testid="modulor-modal-shadow"] {
        display: none;
    }
    [class^="modulor_modal__modal-wrapper"] {
        border: 1px solid var(--divider,var(--divider-main));
    }
}
