.custom-iframe {
  width: 100%;
  overflow: hidden;

  &.kcell_nd,
  &.activ_nd,
  &.tele2_nd,
  &.beeline_nd,
  &.free_money,
  &.qrcode_id,
  &.card_huf_unt2,
  &.card_gf,
  &.bank_transfer_id {
    height: 600px;
  }

  &.conpay_iban {
    height: 1100px;
  }
}

#iframe-host.mobile {
  height: 100%;
}

.submit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;

  &.mobile {
    height: 100%;
    justify-content: space-between;
    min-width: 320px;
    max-width: 720px;
  }

  &__notice-text {
    margin-bottom: 5px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  &__info-link {
    padding-bottom: 12px;
  }

  &__notice-sub-text {
    padding-bottom: 4px;
  }

  &__submit-link {
    padding-bottom: 4px;

    & > button:hover {
      background: transparent !important;
    }

    & span {
      text-transform: capitalize;
      color: var(--text-link);
    }
  }

  &__button-container {
    display: flex;
    padding-top: 16px;
    align-items: stretch;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;
  }
}

.absolute-loader {
  left: 50%;
  position: absolute;
  top: 0;
}
