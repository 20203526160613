.field-wrapper {
    margin-bottom: 20px;
}

.list-wrapper {
    [data-component-name="modulor-list-view"] {
        margin-bottom: 16px;
        padding-bottom: 0;
    }
    .list-box {
        cursor: pointer;
        .action__in-list-cell {
            float: right;
        }
        .radio-btn {
            margin-right: 14px;
        }
        .remove-card-btn {
            max-width: 70px;
        }
    }
    .link-box {
        text-decoration: none;
    }
}

.empty-list-block {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__icon {
        margin-bottom: 16px;
    }
    &__text {
        margin-bottom: 8px;
        text-align: center;
    }
}
