.withdrawal-methods {
  &.desktop {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
  div[data-component-name="modulor-list-view"] {
    border-bottom: none;
  }

  div[class^="modulor_list-cell__icon"] {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__item {
    [data-id="method-logo"] {
      display: flex !important;
      align-items: center;
    }
    [class^="modulor_image__placeholder"], svg {
      width: 40px;
      height: auto;
      display: flex !important;
      align-items: center;
    }
    span[data-id="modulor-content-text-title"] {
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
    }
    span[data-id="modulor-content-text-subtitle"] {
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
    }

    [data-id="method-icon-disabled"] {
      opacity: 0.5;
    }
  }
}

.method-group-caption {
  display: flex;
  flex: 1 0 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-bottom: 4px;

  &__icon {
    margin-right: 4px;
  }
}
