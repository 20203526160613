.deposit-form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 320px;
  max-width: 720px;
  &__content {
    .amount-blocks {
      margin: 4px 0 16px;
    }
    [data-component-name="modulor-field"],
    [data-component-name="modulor-dropdown"] {
      margin-bottom: 8px;
    }
    div[data-component-name="modulor-error"] {
      position: relative;
      bottom: 10px;
    }
  }
  &__divider {
    margin: 8px -16px;
  }
  &__footer {
    margin: 16px 0 16px;
  }

  .modulor__loader {
    position: absolute;
    z-index: 9;
    background-color: var(--background-main);
  }
}