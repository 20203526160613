.status {
  margin: 0;
  text-align: center;
  background: var(--white-color);
  display: flex;
  flex-direction: column;

  .status-icon-wrapper {
    grid-area: image;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 44px;
    margin-bottom: 12px;
  }

  .status-text-wrapper {
    display: grid;
    gap: 4px;
  }

  .bottom-button-wrapper {
    grid-area: button;

    .bottom-button {
      margin-top: 16px;
      margin-inline-start: 0;

      span {
        line-height: normal!important;
      }
    }
  }

}
